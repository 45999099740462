import type { GetServerSidePropsContext } from 'next';

import queryStringify from '@/utils/url/queryStringify';
import { API_HOST } from '@/constants';

import { APIError } from './error';

interface HttpOptions {
  path: string;
  baseURL?: string;
  params?: string;
  method?: string;
  headers?: Record<string, string>;
  body?: Record<string, unknown>;
}

type FetchArgumentType = Parameters<typeof fetch>;

type FetchInitType = NonNullable<FetchArgumentType['1']>;

type FetchBodyType = FetchInitType['body'];

type FetchHeaderType = NonNullable<FetchInitType['headers']>;

/**
 * @function http
 * @param {any} options
 * @param {any} context
 *
 */
const http = async (options: HttpOptions, context?: GetServerSidePropsContext) => {
  const { path, baseURL, params, method = 'GET', headers = {}, body } = options;
  let _params = undefined;
  let _http_body: FetchBodyType = undefined;

  let _headers: FetchHeaderType = { ...headers };
  if ('Content-Type' in _headers) {
    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest_API/Using_FormData_Objects#sending_files_using_a_formdata_object
     * Remove content type because multipart/form-data cannot be set manually.
     * Doing so will prevent the browser from being able to set the Content-Type header
     * with the boundary expression it will use to delimit form fields in the request body
     */
    delete headers['Content-Type'];
  }

  switch (method) {
    case 'DELETE':
    case 'POST':
    case 'PUT':
      if (body && body instanceof FormData) {
        _http_body = body;
      } else if (body) {
        _http_body = JSON.stringify(body);
        _headers['Content-Type'] = 'application/json';
      } else {
        _http_body = '';
      }
      break;
    case 'GET':
      if (body && !(body instanceof FormData)) {
        _params = queryStringify(body);
      } else {
        _params = params;
      }
      _headers['Content-Type'] = 'application/json';
      break;
    default:
      break;
  }

  const base = baseURL || API_HOST;
  const endpoint = base + path;
  const requestPath = endpoint + (_params ? `?${_params}` : '');

  const referer = (context && context.req.headers.referer) ?? '';
  if (referer) {
    _headers = { ..._headers, Referer: referer };
  }

  const cookie = (context && context.req.headers.cookie) ?? '';
  if (cookie) {
    _headers = { ..._headers, Cookie: cookie };
  }

  const response = await fetch(requestPath, {
    method: method,
    body: _http_body,
    headers: _headers,
    credentials: 'include',
  });

  const json = await response.json();
  if (!json && response.status >= 400 && response.status < 500) {
    throw new Error(`[${response.status}] ${response.statusText}`);
  } else if (response.status >= 400) {
    throw new APIError(`[${response.status}] ${response.statusText}`, json);
  }

  return json;
};

export default http;
