/**
 * @constant CONSTANTS
 */
const CONSTANTS = {
  API_HOST: String(process.env.NEXT_PUBLIC_API_HOST ?? ''),
  HOSTNAME: String(process.env.NEXT_PUBLIC_HOSTNAME ?? ''),
};

export const BROKEN_IMAGE = 'https://storage.googleapis.com/besthome/general/20220810205212_4a84b934376b23e8.png';

export const { API_HOST, HOSTNAME } = CONSTANTS;
